import React from 'react';
import { Helmet } from 'react-helmet';
import Button from '../components/Button';
import Layout from '../components/layout/Layout';


const About = () => (
    <Layout>
    <Helmet   
    htmlAttributes={{
    lang: 'en',
    }}>
    <title lang="en">About On The Block Real Estate International</title>
    <meta name="description" content="On The Block Real Estate Has Been Serving California For More Than 20+ Years, 
    Our Services Include Assisting With Buying, Selling, & Refinancing Property. We are now brokered by eXp realty, and 
    offer a streamlined service online to enhance the experience for our clients." lang="en"/>
    </Helmet>

      <section className="pt-20 md:pt-40 AboutBack">
        <div className="mx-auto px-4">
            <h1 className="text-6xl font-bold leading-none text-white pb-40 drop-shadow-sm">
               Get To Know<br/>
               Our Team
            </h1>
        </div>
      </section>
  
      <section className="pt-5">
        <div className="container mx-auto">
        <h1 className="text-center text-2xl lg:text-3xl font-semibold leading-none">
            Things You Must Know About<br/> On The Block Real Estate
        </h1>
          <div className="flex flex-col sm:flex-row sm:-mx-3 mt-12">
          <div className="flex-1 px-10 pb-20">
            <h2 className="text-2xl lg:text-2xl font-semibold text-primary-darker pb-5">An Introduction</h2>
            <p className="text-grey-900">
            We educate Buyers and Sellers of property to make the Right Financial Choices for themselves and 
            the families. We are not Salespeople. We are Educators. We inform, educate and teach all property 
            Sellers and Buyers about how to properly market their properties, or how to properly purchase a 
            property with confidence.
            </p>
  
          </div>
          <div className="flex-1 px-10">
            <h2 className="text-2xl lg:text-2xl font-semibold text-primary-darker pb-5">Our Mission</h2>
            <p className="text-gray-900 ExperienceText">
            Our ongoing mission is to provide the highest level of service to our clients to help them when 
            buying or selling. We aim to assist you in making the best real estate decision possible based on your needs.
            </p>
          </div>
          </div>
        
          <div className="pt-20 px-10 sm:-mx-3">
          <h2 className="text-2xl lg:text-2xl font-semibold text-primary-darker pb-5">Core Values</h2>
          <p className="text-grey-900">
          Our vision demands the very highest level of performance from every team member on every project. 
          We will negotiate every lease as if it were our own. We will seek out every property as if we would 
          be spending our work lives there. We will execute every transaction as if our moral standing hangs 
          in the balance – which it does. We will help our clients implement LEED principles as if our children’s 
          lives depend on it – which they do. Integrity. Responsibility. Sustainability.
          </p>
          </div>
        </div>
      </section>
  
      <section className="container mx-auto my-20 py-24 bg-gray-200 rounded-lg text-center">
        <h3 className="text-5xl font-semibold">Call Our Team Today</h3>
        <p className="mt-8 mb-5 text-xl font-light">
          We are available 24/7 - give us a call today, or feel free to send us a message
          through our chat.
        </p>
        <a href="tel:562-972-1390">
          <Button size="l">
            Call Now
          </Button>
        </a>
      </section>
    </Layout>
  );

export default About;
